import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { media } from "utils/Media";
import MainNav from "components/functional/mainNav";

import Layout from "components/layout";

import { StaticImage } from "gatsby-plugin-image";

const metaData = {
  title: "The clock might be ticking on TikTok | D E W Y N T E R S",
  description:
    "The clock might be ticking on TikTok…but it’s definitely our friend. (For now.)",
  image: "images/news/tiktok.png",
};

const NewsBody = styled.section`
  width: 100%;

  .news {
    width: 85%;
    margin: var(--auto);
    max-width: 900px;
    @media ${media.sm} {
      padding-top: 2rem;
    }
  }

  .app-img {
    margin-bottom: 1rem;
  }
`;
const SingleNewsStyles = styled.article`
  position: relative;
  width: 100%;
  margin-bottom: 4rem;

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
  }

  svg {
    width: 30px;
    height: 30px;
    fill: var(--cyan);
    margin-bottom: 0.5rem;
  }

  h1 {
    font-weight: 800;
    font-size: 1.5rem;

    span {
      color: var(--cyan);
    }
    @media ${media.md} {
      font-size: 33px;
    }
  }

  .body {
    margin-top: 1rem;
    .highlight {
        color: #09dbd8;
    }
    a {
      text-decoration: underline 2px;
      color: #09dbd8 !important;
      &:hover,
      &:active {
        transform: scale(1.5);
      }
    }
    .no-colour-change{
      color: #000!important;
    }
    p {
      margin-bottom: 1rem;

      strong {
        font-weight: 900;
        font-family: var(--PoppinsBold);
      }

      span,
      a {
        color: var(--cyan);
        font-weight: 800;
      }
    }
  }

  @media ${media.sm} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
  @media ${media.xl} {
    .gatsby-image-wrapper {
      height: auto;
    }
  }
`;
const LatestNews = ({ data }) => {
  return (
    <Layout
      title={metaData.title}
      description={metaData.description}
      image={metaData.image}
    >
      <MainNav alt />
      <NewsBody>
        <div className="news">
          <SingleNewsStyles>
            <div className="header">
              <StaticImage
                src="../../images/news/tiktok.png"
                alt="The clock might be ticking on TikTok…but it’s definitely our friend. (For now.) | Dewynters"
                className="app-img"
              />
              <h1>
                The clock might be ticking on TikTok…but it’s definitely our friend. (For now.)
              </h1>
            </div>
          <div className="body">
          <p><strong>TikTok can be a game changer for theatre, and here&rsquo;s why we hope to use this platform to its full potential&hellip;while we still can. </strong></p>
          <i>By Paul Goodman, Organic Social Director</i>
          <br />
          <br />
<p>You&rsquo;ve likely heard that TikTok has taken over the social media landscape in the last few years. But recently it&rsquo;s been in the news for very different reasons. On Thursday 16th March, MP Oliver Dowden announced that TikTok&nbsp;has been banned from UK government phones. Cabinet ministers are concerned that the potential access to our Tik Tok data by the Chinese government is a breach of national security.</p>
<p>We are aware of the shifting landscape, and the concerns of lawmakers in UK, EU and US. But while we monitor the situation carefully, we leave these national security issues to political leaders, and try to focus on how our brands can serve TikTok users in the best way possible.</p>
<p>TikTok allows us to reach large numbers of people at a much lower cost than Meta. But we want to talk about the cost or value of connection. Now you may be forgiven for thinking that TikTok is simply an app for teenagers to post videos of themselves dancing in their bedrooms. And, well, yes that&rsquo;s partly true. But that&rsquo;s nowhere near the whole story.</p>
<p>TikTok makes it easier than any other platform for you to find your target audience and for your target audience to find each other.</p>
<p>If you can think of it, there&rsquo;s a TikTok for it. CoupleTok, for married couples who enjoy playing pranks on each other; BookTok for avid readers; HistoryTok for people who like to learn about ancient civilizations. If you scroll long enough, you can watch iconic moments from EastEnders turned into EuroPop anthems. And yes, there&rsquo;s even MusicalTheatreTok for Musical Theatre enthusiasts. So it&rsquo;s now easier than ever to connect with people over a common interest.</p>
<p>In many ways, TikTok is a theatre producer&rsquo;s best friend. When a user scrolls through TikTok, or other discovery spaces like Instagram Reels, they are mostly being served content from creators that they don&rsquo;t follow, but that TikTok&rsquo;s algorithm thinks they will like. This means that TikTok users are constantly discovering new channels to follow and enjoy. Which, in turn, means that the content you post is popping up on the For You pages of potential new audience members all the time.</p>
<p>While lots of brands are dismissing TikTok as a youth-only platform, we&rsquo;ve been seeing great results for brands with broad appeal like <em>Magic Mike Live</em>, <a href="https://www.tiktok.com/@greasewestend/video/7160675649584254214?lang=en&amp;q=greasewestend&amp;t=1679047524188" target="_blank"><em>Grease</em></a> and <a href="https://www.tiktok.com/@bttfmusical/video/7161780885572963590?lang=en" target="_blank"><em>Back to the Future The Musical</em></a>. Working with Bob Gale, the original creator of <em>Back to the Future</em>, has enabled us to take iconic characters from the franchise and bring them into the present-day using trends and trending sounds. As these snippets of fun, engaging videos clock up views into the millions, we can see all the stages of the marketing funnel unfolding in the comments.</p>
<p>First, fans of the original films are surprised to see that there is a <em>Back to the Future</em> musical. Then they note how closely the West End cast resemble the characters of the film and are won over by how faithful the performances are. They then enjoy the thrill of seeing their favourite characters give their take on a contemporary trend. Finally, after two or three such interactions, they comment to say that they&rsquo;ve booked a ticket.</p>
<p>This is not just about the West End. It&rsquo;s global. TikTok has brought together the Broadway and West End communities like never before, providing a single space for sharing content with like-minded people from around the world.</p>
<p>For this reason, we&rsquo;re seeing several shows choosing to pool all their content from productions around the world into global TikTok accounts. Fandoms for shows like <em>Hamilton</em>, <em>Phantom</em> and <em>Six</em> are coming together to celebrate their favourite cast members from international productions, while <em>Wicked </em>fans have been enjoying content from the new production recently mounted in Brazil.</p>
<p>We&rsquo;re even seeing the content that we create for TikTok topping best performing lists on traditional social media channels like Facebook and Twitter. Productions that are currently playing in this space are benefiting from high reach and successfully attracting audience members of the future.</p>
<p>We asked the agency what role they feel TikTok plays in live entertainment marketing&hellip;.</p>
<p><strong>Nicolle Cannock, Head of Insights</strong></p>
<p>Particularly for urban audiences and audiences under the age of 35, our media consumption analysis shows that TikTok is rapidly increasing in popularity at the same time as Facebook&rsquo;s popularity begins to decrease. 36% of frequent theatregoers use TikTok, compared to 20% of the national average &ndash; compared to 61% of theatregoers using Facebook which under-indexes against the national average (70%).</p>
<p>Through our research we&rsquo;ve also seen that Gen Z audiences are a lot less amenable to being &ldquo;sold to&rdquo;; their emphasis on content over advertising aligns with discovery channels like TikTok, which explains its popularity and success in many of our campaigns. Discovery social channels also give the illusion to the user that they have discovered a new musical themselves, which provides a great hook into awareness-building for audiences who are less responsive to traditional channels.&nbsp;</p>
<p><strong>Hannah Petts, Social Media Director</strong></p>
<p>TikTok has so far provided a very reliable source of low-cost reach for many brands across the UK, including those in the live entertainment industry. When targeting a younger, hyper-engaged theatre fan we often see TikTok deliver CPMs up to 20% lower than Meta &ndash; so TikTok has certainly helped to deliver cost-effective reach to very specific theatre audiences over the last year.</p>
<p>That being said &ndash; from a conversion driving perspective &ndash; other channels are still winning. TikTok&rsquo;s excellent awareness-driving campaigns still typically need to be paired with wider conversion activity in order for producers to see a meaningful uplift in revenue and wraps.</p>
<p><strong>Adam Fulrath, Director of Creative Services </strong></p>
<p>For brand creative to truly succeed on the platform, it needs to be clever, authentic AND native. Our in-house team creates first and foremost for TikTok so users don&rsquo;t feel like they&rsquo;re being served a marketing push. That makes all the difference in building that loving relationship between brand and followers.</p>
<p><strong><em>If you would like to know what opportunities there could be for your show on TikTok and Instagram Reels then do <Link className="no-colour-change" to='/contact-us/'>get in touch.</Link></em></strong></p>
          </div>
            <p>
              <strong>
                <Link to="/opinion/">{`< Back`}</Link>
              </strong>
            </p>
          </SingleNewsStyles>
        </div>
      </NewsBody>
    </Layout>
  );
};

LatestNews.propTypes = {
  data: PropTypes.shape({
    News: PropTypes.any,
  }),
};

export default LatestNews;
